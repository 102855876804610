<template>
  <time :datetime="formattedDate">
    {{ formattedDate }}
    <meta itemprop="datePublished" :content="formattedDate" />
  </time>
</template>

<script setup lang="ts">
import formatDate from "~/utils/customer-area/formatDate";

const props = defineProps({
  timestamp: {
    required: true,
    type: Number,
  },
});

const formattedDate = formatDate(props.timestamp);
</script>
